import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { ExtraParameter } from "../models/extra-parameter";
import constants from "../constants/app-styles";

const styles = StyleSheet.create({
  container: {},
  itemActive: {
    borderColor: constants.colors.primaryLight,
    borderWidth: 1
  },
  extraParamsTitle: {
    marginBottom: 10,
    marginTop: 15,
    fontSize: 16,
    fontWeight: "bold",
    color: constants.colors.light2
  },
  card: {
    borderColor: constants.colors.dark3,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 12,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: constants.colors.dark2,
    marginBottom: 8
  },
  extraParamsText: {
    color: constants.colors.light2,
    fontSize: 16
  }
});

interface GameConfigurationFormProps {
  values: {};
  extraParams: ExtraParameter[];
  onValueChange: (name: string, value: string | number) => void;
}

export default function GameConfigurationForm({ values, extraParams = [], onValueChange }: GameConfigurationFormProps) {
  const renderExtraParam = (extraParam: ExtraParameter) => (
    <View key={extraParam.name} style={styles.container}>
      {extraParam.type === "select" && (
        <View>
          <Text style={styles.extraParamsTitle}>{extraParam.label}</Text>
          <>
            {extraParam.options.map(e => {
              const cardContentStyle = values[extraParam.name] === e.value ? styles.itemActive : {};

              return (
                <TouchableWithoutFeedback
                  key={extraParam.name}
                  style={[styles.card, cardContentStyle]}
                  onPress={() => onValueChange(extraParam.name, e.value)}
                >
                  <Text style={styles.extraParamsText}> {e.name}</Text>
                </TouchableWithoutFeedback>
              );
            })}
          </>
        </View>
      )}
      {extraParam.type === "number" && <View /> /* TODO: */}
      {extraParam.type === "text" && <View /> /* TODO: */}
    </View>
  );

  return <>{extraParams.map(renderExtraParam)}</>;
}
