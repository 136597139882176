import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { persistConfig } from "./persistConfig";

export const SET_PLAYERS = "SET_PLAYERS";

function players(state: Player[] = [], action) {
  if (action.type === SET_PLAYERS) {
    return action.players;
  } else {
    return state;
  }
}

export interface ApplicationState {
  players: Player[];
}

const persistedReducer = persistReducer(persistConfig, combineReducers({ players }));

export default () => {
  const store = createStore(persistedReducer, { players: [] });
  const persistor = persistStore(store);

  return { store, persistor };
};
