import React, { PropsWithChildren } from "react";
import { StyleSheet } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import constants from "../constants/app-styles";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    flex: 1
  }
});

const LinearGradientContainer = ({ children }: PropsWithChildren<{}>) => {
  return (
    <LinearGradient colors={[constants.colors.dark3, constants.colors.dark1]} style={styles.container}>
      {children}
    </LinearGradient>
  );
};

export default LinearGradientContainer;
