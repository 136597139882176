import React, { useCallback, useState, useEffect } from "react";
import { FlatList, ScrollView, TouchableWithoutFeedback } from "react-native-gesture-handler";
import { View, StyleSheet, Text, Dimensions } from "react-native";
import { MaterialTopTabNavigationProp } from "@react-navigation/material-top-tabs";
import { CompositeNavigationProp, useFocusEffect } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MaterialIcons } from "@expo/vector-icons";
import PlayerListItem from "../components/player-list-item";
import { usePlayerListContext } from "../context/player-list-context";
import EmptyPlayerPool from "../components/empty-player-pool";
import { PlayerPoolTabsParamList, LinkedStackParamList } from "../navigation";
import analyticsService from "../services/analytics/analytics-service";
import { ButtonId, Screen } from "../services/analytics/types";
import { useSelectedGameContext } from "../context/selected-game-context";
import LinearGradientContainer from "../components/linear-gradient-container";
import constants from "../constants/app-styles";
import { GameInfoBar } from "../components/game-info-bar";
import ScoliaButton from "../components/scolia-button";
import Card from "../components/card";
import { renderNumberOfPlayers } from "../utils/number-of-players";

interface PlayerPoolScreenProps {
  navigation: CompositeNavigationProp<
    MaterialTopTabNavigationProp<PlayerPoolTabsParamList>,
    StackNavigationProp<LinkedStackParamList>
  >;
}

export default function PlayerPoolScreen({ navigation }: PlayerPoolScreenProps) {
  const { players, removePlayer, updatePlayer, selectedPlayers } = usePlayerListContext();
  const { selectedGame } = useSelectedGameContext();
  const windowWidth = Dimensions.get("window").width;
  const [isErrorMsgVisible, setIsErrorMsgVisible] = useState<boolean>(false);

  useFocusEffect(() => {
    analyticsService.setScreen(Screen.PH_02);
  });

  useEffect(() => {
    if (selectedPlayers) {
      setIsErrorMsgVisible(false);
    }
  }, [selectedPlayers]);

  const isSelectedPlayersAmountCorrect = useCallback(() => {
    if (selectedGame) {
      return selectedGame.numberOfPlayers.includes(selectedPlayers.length);
    }
  }, [selectedPlayers, selectedGame]);

  const notificationMsg = () => {
    return (
      <Card
        containerStyle={styles.notificationMessageContainer}
        left={<MaterialIcons name="info" size={26} color={constants.colors.red} />}
        title={
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}>
              {Math.max(...selectedGame.numberOfPlayers) < selectedPlayers.length
                ? "Too many selected players"
                : "Select more player(s)"}
            </Text>
            <TouchableWithoutFeedback onPress={() => setIsErrorMsgVisible(false)}>
              <MaterialIcons name="close" size={26} color={constants.colors.dark4} />
            </TouchableWithoutFeedback>
          </View>
        }
        content={
          <Text style={styles.contentText}>
            Make sure that
            {
              <Text style={styles.numberOfPlayersTextInContent}>
                {` ${renderNumberOfPlayers(selectedGame.numberOfPlayers)} `}
              </Text>
            }
            players are selected!
          </Text>
        }
      ></Card>
    );
  };

  const styles = createStyles(windowWidth);

  return (
    <LinearGradientContainer>
      {selectedGame && <GameInfoBar game={selectedGame} />}
      <View style={styles.mainContainer}>
        <View style={styles.addButtonContainer}>
          <Text style={[styles.selectPlayersText, styles.selectPlayersTextFontStyle]}>Select players</Text>
          <ScoliaButton
            variant="flat"
            iconPosition="left"
            text="Add new player"
            icon="add"
            testID="add-player-navigate-button"
            onPress={() => {
              analyticsService.logButtonClick(ButtonId.PH_03_01);
              navigation.navigate("AddPlayer");
            }}
          ></ScoliaButton>
        </View>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
          {players.length > 0 && (
            <FlatList
              testID="players-flatlist"
              data={players}
              keyExtractor={player => player.name}
              renderItem={({ item }) => (
                <PlayerListItem
                  player={item}
                  onPlayerRemoved={removePlayer}
                  onSelectedChanged={selected => updatePlayer(item, { selected })}
                />
              )}
            />
          )}
          {players.length === 0 && <EmptyPlayerPool />}
        </ScrollView>
        <ScoliaButton
          style={styles.scoliaButton}
          text="Start Game"
          onPress={() => {
            if (isSelectedPlayersAmountCorrect()) {
              analyticsService.logButtonClick(ButtonId.PH_03_02);
              navigation.navigate("GameStack", { screen: "LoadGame" });
            } else {
              setIsErrorMsgVisible(true);
            }
          }}
          testID="games-list-navigate-button"
        ></ScoliaButton>
      </View>
      {isErrorMsgVisible && notificationMsg()}
    </LinearGradientContainer>
  );
}

const createStyles = windowWidth =>
  StyleSheet.create({
    selectPlayersText: {
      color: constants.colors.light2,
      fontWeight: "bold"
    },
    scoliaButton: {
      width: "100%",
      marginVertical: 16
    },
    selectPlayersTextFontStyle: {
      marginVertical: windowWidth > 768 ? 28 : 18,
      fontSize: windowWidth > 768 ? 24 : 16
    },
    mainContainer: {
      flex: 1,
      marginHorizontal: windowWidth > 768 ? 60 : 20
    },
    titleContainer: {
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center"
    },
    titleText: {
      fontWeight: "bold",
      fontSize: 12,
      color: constants.colors.red
    },
    notificationMessageContainer: {
      padding: 12,
      height: 82,
      left: 20,
      bottom: 80,
      right: 20,
      position: "absolute",
      zIndex: 2
    },
    contentText: {
      fontSize: 12,
      color: constants.colors.light2
    },
    numberOfPlayersTextInContent: {
      fontSize: 12,
      color: constants.colors.red
    },
    addButtonContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 10,
      alignItems: "center"
    }
  });
