import React, { PropsWithChildren } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ApplicationState, SET_PLAYERS } from "../../store";
import { PlayerStore } from "./player-store";
import { PlayerListContext } from "./player-list-context";

interface PlayerListProviderProps {
  players: Player[];
  setPlayers: (players: Player[]) => void;
}

const PlayerListProvider = ({ children, players, setPlayers }: PropsWithChildren<PlayerListProviderProps>) => {
  return (
    <PlayerListContext.Provider value={new PlayerStore(players, setPlayers)}>{children}</PlayerListContext.Provider>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  players: state.players
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPlayers: (players: Player[]) => dispatch({ type: SET_PLAYERS, players })
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerListProvider);
