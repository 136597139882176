import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import confirm from "../services/confirmation-service";
import { usePlayerListContext } from "../context/player-list-context";
import constants from "../constants/app-styles";
import Card from "./card";

const styles = StyleSheet.create({
  itemActive: {
    borderColor: constants.colors.primaryLight,
    borderWidth: 2
  },
  card: {
    height: 48,
    marginBottom: 12
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: constants.colors.dark3
  },
  leftContainer: {
    marginLeft: 8,
    justifyContent: "center",
    flex: 1
  },
  titleContainer: {
    justifyContent: "space-between",
    flexDirection: "row"
  },
  title: {
    fontSize: 16
  },
  capitalLetterContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: constants.colors.dark2
  },
  capitalLetter: {
    fontSize: 20,
    color: constants.colors.grey2
  },
  badgeContainer: {
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: constants.colors.yellow,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginRight: 14
  },
  badgeText: {
    fontSize: 14,
    color: constants.colors.dark1
  }
});

interface PlayerListItemProps {
  player: Player;
  onPlayerRemoved: (player: Player) => void;
  onSelectedChanged: (selected: boolean) => void;
}

export default function PlayerListItem({ player, onPlayerRemoved, onSelectedChanged }: PlayerListItemProps) {
  const { selectedPlayers } = usePlayerListContext();
  const isActive = player.selected ? styles.itemActive : {};
  const badgeVisible = selectedPlayers.indexOf(player) + 1 > 0;
  const titleColor = player.selected ? constants.colors.primaryLight : constants.colors.light2;

  const showRemoveConfirmationDialog = () => {
    confirm({
      title: "Remove user",
      message: `Do you really want to remove ${player.name}?`,
      onConfirm: () => onPlayerRemoved(player)
    });
  };

  return (
    <TouchableWithoutFeedback
      onLongPress={() => showRemoveConfirmationDialog()}
      onPress={() => onSelectedChanged(!player.selected)}
    >
      <Card
        containerStyle={[isActive, styles.card]}
        left={
          <View style={styles.leftContainer}>
            {player.image && <Image source={{ uri: player.image }} style={styles.icon} />}
            {!player.image && (
              <View style={[styles.icon, styles.capitalLetterContainer]}>
                <Text style={styles.capitalLetter}>{player.name.charAt(0).toLocaleUpperCase()}</Text>
              </View>
            )}
          </View>
        }
        title={
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View style={styles.titleContainer}>
              <Text style={[{ color: titleColor }, styles.title]}>{player.name}</Text>
              {badgeVisible && (
                <View style={styles.badgeContainer}>
                  <Text style={styles.badgeText}>{player.order}</Text>
                </View>
              )}
            </View>
          </View>
        }
      ></Card>
    </TouchableWithoutFeedback>
  );
}
