import analyticsService from "../../services/analytics/analytics-service";
import { LogEvent } from "../../services/analytics/types";

export class PlayerStore {
  public selectedPlayers;

  constructor(public players: Player[], private setPlayers: (players: Player[]) => void) {
    this.selectedPlayers = players.filter(player => player.selected);
  }

  private updatePlayerList = (players: Player[]) => {
    this.setPlayers([...players]);
    this.selectedPlayers = players.filter(player => player.selected);
  };

  public updatePlayer = (player: Player, values: Partial<Player>) => {
    const index = this.players.indexOf(player);
    if (index > -1) {
      if (values.hasOwnProperty("selected")) {
        analyticsService.logEvent(values.selected ? LogEvent.PLAYER_SELECTED : LogEvent.PLAYER_DESELECTED);

        if (values.selected) {
          this.selectPlayer(player);
        } else {
          this.unselectPlayer(player);
        }
      }
      this.players[index] = { ...player, ...values };
      this.updatePlayerList(this.players);
    }
  };

  public removePlayer = (player: Player) => {
    const index = this.players.indexOf(player);
    if (index > -1) {
      this.players.splice(index, 1);

      if (player.selected) {
        this.unselectPlayer(player);
      }

      this.updatePlayerList(this.players);
      analyticsService.logEvent(LogEvent.PLAYER_REMOVED);
    }
  };

  public addPlayer = (player: Player) => {
    if (!this.players.includes(player)) {
      this.players = [player, ...this.players];
      this.setPlayers(this.players);

      this.selectPlayer(player);

      analyticsService.logEvent(LogEvent.PLAYER_ADDED);
    }
  };

  private selectPlayer = (player: Player) => {
    player.order = this.selectedPlayers.length + 1;
  };

  private unselectPlayer = (unselectedPlayer: Player) => {
    this.selectedPlayers.forEach(selectedPlayer => {
      if (selectedPlayer.order > unselectedPlayer.order) {
        selectedPlayer.order--;
      }
    });
  };
}
