import React from "react";
import { StyleSheet, View, Text, StyleProp, ViewStyle } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialIcons } from "@expo/vector-icons";
import constants from "../constants/app-styles";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row"
  },
  rowReverse: {
    flexDirection: "row-reverse"
  },
  column: { flexDirection: "column" },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 5
  },
  defaultButton: {
    height: 44,
    borderRadius: 4
  },
  flatButton: {
    backgroundColor: "transparent"
  },
  text: { fontSize: 16, marginHorizontal: 8 },
  defaultButtonText: { fontWeight: "bold", color: "black" },
  flatButtonText: { color: constants.colors.primaryLight }
});

interface SoliaButtonProps {
  text?: string;
  style?: StyleProp<ViewStyle>;
  variant?: "default" | "flat";
  icon?: string;
  iconPosition?: "top" | "left" | "right";
  onPress: () => void;
  width?: string;
  iconColor?: string;
  testID?: string;
}

export default function ScoliaButton({
  text,
  variant = "default",
  icon,
  iconPosition,
  onPress,
  iconColor = constants.colors.primaryLight,
  testID,
  style
}: SoliaButtonProps) {
  const isVariantDefault = variant === "default";

  const buttonFlexDirection = () => {
    if (iconPosition === "left") {
      return styles.row;
    } else if (iconPosition === "right") {
      return styles.rowReverse;
    } else if (iconPosition === "top") {
      return styles.column;
    }
  };

  return (
    <View style={[style]} testID={testID}>
      <TouchableWithoutFeedback onPress={onPress}>
        {isVariantDefault && (
          <LinearGradient
            colors={[constants.colors.primaryLight, constants.colors.primary]}
            style={[styles.defaultButton, styles.buttonContainer, buttonFlexDirection()]}
          >
            {icon && <MaterialIcons name={icon} size={26} color="black" />}
            <Text style={[styles.text, styles.defaultButtonText]}>{text}</Text>
          </LinearGradient>
        )}
        {!isVariantDefault && (
          <View style={[styles.flatButton, styles.buttonContainer, buttonFlexDirection()]}>
            {icon && <MaterialIcons name={icon} size={24} color={iconColor} />}
            {text && <Text style={[styles.text, styles.flatButtonText]}>{text}</Text>}
          </View>
        )}
      </TouchableWithoutFeedback>
    </View>
  );
}
