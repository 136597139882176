import React, { useState, useEffect, useRef, useCallback } from "react";
import { Text, View, TouchableOpacity, StyleSheet, Platform } from "react-native";
import { Camera, Constants } from "expo-camera";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import * as Permissions from "expo-permissions";
import { ActivityIndicator } from "react-native-paper";
import analyticsService from "../services/analytics/analytics-service";
import { LogEvent } from "../services/analytics/types";
import { cropImage } from "../utils/camera";
import constants from "../constants/app-styles";

const DESIRED_RATIO = "16:9";

const styles = StyleSheet.create({
  icon: {
    color: "#fff",
    fontSize: 40
  },
  icontouch: {
    alignSelf: "flex-end",
    alignItems: "center",
    backgroundColor: "transparent"
  },
  buttonrow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    margin: 30
  }
});

interface TakePictureProps {
  onPictureTaken: (photo: string) => void;
}

export const TakePicture = ({ onPictureTaken }: TakePictureProps) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState<string>();
  const [ratio, setRatio] = useState(DESIRED_RATIO);
  const camera = useRef(null);

  useEffect(() => {
    async function requestPermission() {
      const { status } = await Permissions.askAsync(Permissions.CAMERA);

      if (status === "granted") {
        setType(Constants.Type.front);
      }

      setHasPermission(status === "granted");
      setLoading(false);
    }
    requestPermission();
  }, []);

  const prepareRatio = useCallback(async () => {
    if (Platform.OS === "android" && camera.current) {
      const ratios = await camera.current.getSupportedRatiosAsync();

      // See if the current device has your desired ratio, otherwise get the maximum supported one
      // Usually the last element of "ratios" is the maximum supported ratio
      setRatio(ratios.find(r => r === DESIRED_RATIO) || ratios[ratios.length - 1]);
    }
  }, []);

  const onCameraReady = useCallback(async () => {
    await prepareRatio();
  }, [prepareRatio]);

  const takePicture = useCallback(async () => {
    try {
      if (camera) {
        const photo = await camera.current.takePictureAsync({ quality: 1.0, base64: true });
        setLoading(true);
        const croppedImageBase64 = await cropImage(photo);
        analyticsService.logEvent(LogEvent.TAKE_PHOTO);
        onPictureTaken(croppedImageBase64);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [onPictureTaken]);

  const flipCamera = useCallback(() => {
    setType(type === Constants.Type.back ? Constants.Type.front : Constants.Type.back);
  }, [type]);

  return (
    <View style={{ flex: 1, justifyContent: "center" }}>
      {!loading && !hasPermission && <Text testID="camera-not-enabled-text">Camera not enabled</Text>}
      {loading && <ActivityIndicator size="large" color={constants.colors.primaryLight} />}
      {!loading && (
        <Camera style={{ flex: 1 }} type={type} ref={camera} onCameraReady={onCameraReady} ratio={ratio}>
          <View style={styles.buttonrow}>
            <TouchableOpacity style={styles.icontouch} onPress={takePicture}>
              <FontAwesome name="camera" style={styles.icon} />
            </TouchableOpacity>
            {Platform.OS !== "web" && (
              <TouchableOpacity style={styles.icontouch} onPress={flipCamera}>
                <MaterialCommunityIcons name="camera-switch" style={styles.icon} />
              </TouchableOpacity>
            )}
          </View>
        </Camera>
      )}
    </View>
  );
};
