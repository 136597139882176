import React, { useState, useCallback } from "react";
import { View, StyleSheet, Image, Text, ActivityIndicator } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { MaterialIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import constants from "../constants/app-styles";
import { isCameraAvailable } from "../utils/camera";
import { TakePicture } from "./take-picture";
import ScoliaTextInput from "./scolia-text-input";
import ScoliaButton from "./scolia-button";

const styles = StyleSheet.create({
  mainContainer: {
    flexGrow: 1
  },
  scoliaButton: {
    width: "100%",
    marginVertical: 16
  },
  cameraContainer: {
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  cameraIcon: {
    height: 160,
    width: 160,
    borderRadius: 80,
    backgroundColor: constants.colors.dark3,
    alignItems: "center",
    justifyContent: "center"
  },
  takePictureText: {
    color: constants.colors.primaryLight,
    fontSize: 16,
    textDecorationLine: "underline"
  },
  deletePictureText: {
    color: constants.colors.lightGrey,
    fontSize: 16,
    textDecorationLine: "underline"
  }
});

interface AddPlayerFormProps {
  onSubmit: (values: { name: string; image: string }) => void;
  isNameTaken: (name: string) => boolean;
}

export default function AddPlayerForm({ onSubmit, isNameTaken }: AddPlayerFormProps) {
  const [name, setName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cameraIsVisible, setCameraVisible] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string>();
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [loading, setLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      const checkCameraAvailability = async () => {
        const cameraAvailable = await isCameraAvailable();

        setCameraAvailable(cameraAvailable);
        setLoading(false);
      };

      checkCameraAvailability();
    }, [])
  );

  const validateName = newName => {
    if (newName && !isNameTaken(newName)) {
      setErrorMessage("");
    } else {
      setErrorMessage(!newName ? "Please name the player" : "Player names must be unique");
    }
  };

  const onNameChange = (newName: string) => {
    validateName(newName);
    setName(newName);
  };

  const pictureTaken = (photo: string) => {
    setPhoto(photo);
    setCameraVisible(false);
  };

  const submit = () => {
    validateName(name);
    onSubmit({ name, image: photo ? photo : null });
  };

  const renderPictureButtons = () => {
    return (
      <>
        <TouchableWithoutFeedback onPress={() => setCameraVisible(true)} style={{ marginVertical: 20 }}>
          <Text style={styles.takePictureText}>Take picture</Text>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={() => setPhoto(undefined)} disabled={!photo}>
          <Text style={styles.deletePictureText}>Delete picture</Text>
        </TouchableWithoutFeedback>
      </>
    );
  };

  return cameraIsVisible ? (
    <TakePicture onPictureTaken={pictureTaken} />
  ) : (
    <View style={styles.mainContainer}>
      <View style={styles.cameraContainer}>
        {loading && <ActivityIndicator size="large" color={constants.colors.primaryLight} />}
        {photo && <Image style={{ height: 160, width: 160 }} source={{ uri: photo }} />}
        {!photo && cameraAvailable && (
          <View style={styles.cameraIcon}>
            <MaterialIcons name="photo-camera" size={80} color={constants.colors.grey1} />
          </View>
        )}
        {cameraAvailable && renderPictureButtons()}
      </View>
      <ScoliaTextInput
        placeholder="Player's name"
        maxLength={50}
        value={name}
        onChange={onNameChange}
        error={errorMessage}
      />
      <ScoliaButton style={styles.scoliaButton} text="Add player" onPress={submit}></ScoliaButton>
    </View>
  );
}
