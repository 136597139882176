import React, { useState, PropsWithChildren } from "react";
import { Game } from "../../models/game";
import { SelectedGameContext } from "./selected-game-context";

export const SelectedGameProvider = (props: PropsWithChildren<{}>) => {
  const [selectedGame, setSelectedGame] = useState<Game>(null);

  return (
    <SelectedGameContext.Provider value={{ selectedGame, setSelectedGame }}>
      {props.children}
    </SelectedGameContext.Provider>
  );
};
