import React from "react";
import { Game } from "../../models/game";

interface SelectedGameContextType {
  selectedGame: Game;
  setSelectedGame: (game: Game) => void;
}

export const SelectedGameContext = React.createContext<SelectedGameContextType>({
  selectedGame: null,
  setSelectedGame: () => undefined
});

SelectedGameContext.displayName = "SelectedGame";
