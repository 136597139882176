import React, { useCallback, useState } from "react";
import { ScrollView, StyleSheet, Text, View, Dimensions } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useFocusEffect } from "@react-navigation/native";
import GameConfigurationForm from "../components/game-configuration-form";
import { useGameServiceContext } from "../context/game-service-context";
import { GameStackParamList } from "../navigation";
import { ExtraParameter } from "../models/extra-parameter";
import analyticsService from "../services/analytics/analytics-service";
import { ButtonId, Screen } from "../services/analytics/types";
import { useSelectedGameContext } from "../context/selected-game-context";
import Toolbar from "../components/toolbar";
import LinearGradientContainer from "../components/linear-gradient-container";
import constants from "../constants/app-styles";
import SoliaIconButton from "../components/scolia-icon-button";

type ConfigurationScreenNavigationProp = StackNavigationProp<GameStackParamList, "Configuration">;

interface ConfigurationScreenProps {
  navigation: ConfigurationScreenNavigationProp;
}

const createStyles = windowWidth =>
  StyleSheet.create({
    mainContainer: {
      flex: 1,
      marginHorizontal: windowWidth > 768 ? 60 : 20
    },
    fab: {
      margin: 20,
      alignSelf: "flex-end"
    },
    customContentMargin: { marginTop: 20 }
  });

export default function ConfigurationScreen({ navigation }: ConfigurationScreenProps) {
  const gameService = useGameServiceContext();
  const { selectedGame } = useSelectedGameContext();
  const { extraParams } = selectedGame;
  const windowWidth = Dimensions.get("window").width;

  const paramReducer = (values: object, param: ExtraParameter) => {
    values = {
      ...values,
      [param.name]: param.type === "select" ? param.options[0].value : null
    };
    return values;
  };

  const [values, setValues] = useState(extraParams.reduce(paramReducer, {}));

  useFocusEffect(
    useCallback(() => {
      gameService.sendConfigChangedEvent(values);
    }, [values])
  );

  useFocusEffect(() => {
    analyticsService.setScreen(Screen.PH_07);
  });

  const onValueChange = (name, value) => {
    const newValues = { ...values, [name]: value };
    setValues(newValues);
  };

  const styles = createStyles(windowWidth);

  return (
    <LinearGradientContainer>
      <Toolbar
        canGoBack
        onBackClick={() => navigation.navigate("Games")}
        customContent={
          <View
            style={{
              flex: 1
            }}
          >
            <Text style={{ fontSize: 16, color: constants.colors.light2 }}>Back to Menu</Text>
          </View>
        }
      />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={styles.mainContainer}>
        <GameConfigurationForm values={values} onValueChange={onValueChange} extraParams={extraParams} />
      </ScrollView>
      <SoliaIconButton
        style={styles.fab}
        icon="arrow-forward"
        onPress={() => {
          analyticsService.logButtonClick(ButtonId.PH_06_01);
          navigation.navigate("Game");
        }}
      ></SoliaIconButton>
    </LinearGradientContainer>
  );
}
