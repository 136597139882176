import React from "react";
import { StyleSheet, View, Image, Text } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Game } from "../models/game";
import { renderNumberOfPlayers } from "../utils/number-of-players";
import constants from "../constants/app-styles";
import env from "../../env";
import Toolbar from "./toolbar";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: "8%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  gameIcon: {
    height: 32,
    width: 32
  },
  gameTitle: {
    color: constants.colors.light2,
    fontSize: 16,
    marginLeft: 8
  },
  numberOfPlayers: {
    color: constants.colors.light2,
    fontSize: 14,
    marginLeft: 5
  }
});

interface GameInfoBarProps {
  game: Game;
}

export const GameInfoBar = ({ game }: GameInfoBarProps) => (
  <Toolbar
    canGoBack={false}
    customContent={
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <Image source={{ uri: env.SERVER_URL + game.icon }} style={styles.gameIcon} />
          <Text style={styles.gameTitle}>{game.name}</Text>
        </View>
        <View style={styles.contentContainer}>
          <MaterialIcons name="person" size={22} color={constants.colors.light2} />
          <Text style={styles.numberOfPlayers}>{renderNumberOfPlayers(game.numberOfPlayers)}</Text>
        </View>
      </View>
    }
  />
);
