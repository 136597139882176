import React from "react";
import { StyleSheet, View, TouchableWithoutFeedback } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import constants from "../constants/app-styles";

const createStyles = canGoBack =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      height: 48,
      width: "100%",
      elevation: 5,
      shadowColor: "black",
      shadowOpacity: 1,
      backgroundColor: constants.colors.dark3,
      paddingRight: canGoBack ? 46 : 0
    },
    iconContainer: {
      width: 46,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row"
    }
  });

interface ToolbarProps {
  canGoBack: boolean;
  customContent: React.ReactNode;
  onBackClick?: () => void;
}

export default function Toolbar({ canGoBack, customContent, onBackClick }: ToolbarProps) {
  const navigation = useNavigation();

  const styles = createStyles(canGoBack);

  const backFunction = onBackClick ? () => onBackClick() : () => navigation.goBack();

  return (
    <View style={[styles.container]}>
      {canGoBack && (
        <View style={styles.iconContainer}>
          <TouchableWithoutFeedback onPress={backFunction}>
            <MaterialIcons name="arrow-back" size={24} color={constants.colors.light2} />
          </TouchableWithoutFeedback>
        </View>
      )}
      {customContent}
    </View>
  );
}
