import React from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import PlayerPoolScreen from "../screens/player-pool-screen";
import GameListScreen from "../screens/game-list-screen";
import constants from "../constants/app-styles";
import { PlayerPoolTabsParamList } from ".";

const PlayerPoolTabs = createMaterialTopTabNavigator<PlayerPoolTabsParamList>();

const PlayerPoolNavigator = () => {
  return (
    <PlayerPoolTabs.Navigator
      initialRouteName="Games"
      tabBarOptions={{
        labelStyle: { fontSize: 16 },
        inactiveTintColor: constants.colors.light1,
        activeTintColor: constants.colors.primaryLight,
        tabStyle: { height: 44 },
        style: {
          backgroundColor: constants.colors.dark1,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5
        },
        indicatorStyle: { height: 0 }
      }}
    >
      <PlayerPoolTabs.Screen name="Games" component={GameListScreen} />
      <PlayerPoolTabs.Screen name="Players" component={PlayerPoolScreen} />
    </PlayerPoolTabs.Navigator>
  );
};

export default PlayerPoolNavigator;
