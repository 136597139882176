import {
  LINK_REQUEST,
  LINK_REQUEST_WITH_TOKEN,
  LINK_RESPONSE,
  UNLINK_REQUEST,
  CONFIG_CHANGED,
  LIST_GAMES_REQUEST,
  LIST_GAMES_RESPONSE,
  SELECT_GAME_REQUEST,
  LOAD_GAME,
  START_GAME,
  ABORT_GAME,
  GAME_READY,
  GAME_OVER,
  PLAYER_LIST_CHANGED,
  UNLINK,
  SWITCH_TO_NEXT_PLAYER
} from "../constants/socket-messages";
import { Game } from "../models/game";
import analyticsService from "./analytics/analytics-service";
import { LogEvent } from "./analytics/types";

export class GameService {
  private onLinkedChangeCallback: (isLinked: boolean) => void = () => undefined;
  private linkToken = "";

  constructor(private socket: SocketIOClient.Socket) {
    this.socket.on("reconnect", () => {
      if (this.linkToken !== "") {
        this.sendLinkRequestWithToken();
      }
    });
  }

  public sendLinkRequest(code: string, onResponse: (response: LinkResponse) => void): void {
    this.socket.emit(LINK_REQUEST, { code });
    this.socket.once(LINK_RESPONSE, (response: LinkResponse) => {
      if (response.success) {
        analyticsService.setUserId(code);
        analyticsService.logEvent(LogEvent.LINK);
        this.linkToken = response.linkToken;
      }

      onResponse(response);
      this.onLinkedChangeCallback(response.success);

      if (response.success) {
        this.socket.once(UNLINK, () => {
          analyticsService.logEvent(LogEvent.UNLINK);
          analyticsService.resetAnalyticsData();
          this.onLinkedChangeCallback(false);
          this.linkToken = "";
        });
      }
    });
  }

  private sendLinkRequestWithToken(): void {
    const code: string = this.linkToken;
    this.socket.emit(LINK_REQUEST_WITH_TOKEN, { code });
    this.socket.once(LINK_RESPONSE, (response: LinkResponse) => {
      if (response.success) {
        analyticsService.logEvent(LogEvent.LINK);
        this.linkToken = response.linkToken;
      } else {
        this.linkToken = "";
        this.onLinkedChangeCallback(false);
      }

      this.onLinkedChangeCallback(response.success);
    });
  }

  public sendUnlinkRequest(): void {
    analyticsService.logEvent(LogEvent.UNLINK_REQUEST);
    this.socket.emit(UNLINK_REQUEST);

    this.onLinkedChangeCallback(false);
  }

  public onLinkedChanged(onChange: (isLinked: boolean) => void) {
    this.onLinkedChangeCallback = onChange;
  }

  public sendConfigChangedEvent(configuration: Configuration): void {
    analyticsService.setGameConfiguration(configuration);
    this.socket.emit(CONFIG_CHANGED, configuration);
  }

  public sendListGamesRequest(onResponse: (games: Game[]) => void): void {
    analyticsService.logEvent(LogEvent.GAME_LIST_REQUEST);
    this.socket.emit(LIST_GAMES_REQUEST);
    this.socket.once(LIST_GAMES_RESPONSE, (games: Game[]) => {
      analyticsService.logEvent(LogEvent.GAME_LIST_RESPONSE);
      onResponse(games);
    });
  }

  public sendSelectGameRequest(gameId: string): void {
    analyticsService.setSelectedGame(gameId);
    this.socket.emit(SELECT_GAME_REQUEST, { gameId });
  }

  public sendLoadGameEvent(onReady: () => void): void {
    analyticsService.logEvent(LogEvent.GAME_LOAD);
    this.socket.emit(LOAD_GAME);
    this.socket.once(GAME_READY, () => {
      analyticsService.logEvent(LogEvent.GAME_LOADED);
      onReady();
    });
  }

  public sendStartGameEvent(onGameOver: () => void): void {
    analyticsService.logEvent(LogEvent.GAME_START);
    this.socket.emit(START_GAME);
    this.socket.once(GAME_OVER, () => {
      analyticsService.logEvent(LogEvent.GAME_OVER);
      onGameOver();
    });
  }

  public sendAbortGameEvent(): void {
    analyticsService.logEvent(LogEvent.ABORT_GAME);
    analyticsService.resetAnalyticsData();
    this.socket.emit(ABORT_GAME);
  }

  public sendPlayerListChangedEvent(players: Player[]): void {
    analyticsService.setSelectedPlayers(players);
    players.sort((a, b) => (a.order > b.order ? 1 : -1));
    this.socket.emit(PLAYER_LIST_CHANGED, players);
  }

  public sendSwitchToNextPlayer(): void {
    this.socket.emit(SWITCH_TO_NEXT_PLAYER);
  }
}
