import React, { useContext, useState, useCallback } from "react";
import { StyleSheet, View, Image, Platform, ImageBackground } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import GameServiceContext from "../context/game-service-context";
import logo from "../styles/resources/images/scoliaLogo.png";
import analyticsService from "../services/analytics/analytics-service";
import { Screen, ButtonId, LogEvent } from "../services/analytics/types";
import PlayStore from "../components/play-store-card";
import ScoliaTextInput from "../components/scolia-text-input";
import ScoliaButton from "../components/scolia-button";
import Card from "../components/card";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2
  },
  image: {
    height: 272,
    width: 280
  },
  imageView: {
    alignItems: "center",
    flex: 0.9,
    justifyContent: "center"
  },
  imgBackground: {
    height: "100%",
    width: "100%",
    flex: 1,
    opacity: 0.9,
    zIndex: 1,
    position: "absolute"
  },
  wrapper: {
    flex: 1
  }
});

export default function StartScreen() {
  const gameService = useContext(GameServiceContext);
  const [input, setInput] = useState<string>("");

  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const onInputTextChange = inputtext => {
    setInput(inputtext.toUpperCase());
  };

  const onSubmitButtonPressed = () => {
    analyticsService.logButtonClick(ButtonId.PH_01_01);
    if (input) {
      gameService.sendLinkRequest(input, (response: LinkResponse) => {
        if (!response.success) {
          setShowErrorMessage(true);
          analyticsService.logEvent(LogEvent.LINK_FAILED);
        }
      });
    } else {
      setShowErrorMessage(true);
    }
  };

  const errorText = useCallback(() => {
    return showErrorMessage ? "Code is invalid" : null;
  }, [showErrorMessage]);

  useFocusEffect(() => {
    analyticsService.setScreen(Screen.PH_01);
  });

  return (
    <View style={styles.wrapper}>
      <ImageBackground
        source={require("../styles/resources/images/background.jpg")}
        style={styles.imgBackground}
      ></ImageBackground>
      <View style={styles.container}>
        <View testID="logo-start-screen" style={styles.imageView}>
          <Image style={styles.image} source={logo} />
        </View>
        <Card
          containerStyle={{ width: "85%", paddingHorizontal: 25, paddingVertical: 30 }}
          content={
            <View>
              <ScoliaTextInput
                style={{ marginBottom: 20 }}
                maxLength={6}
                onChange={onInputTextChange}
                testID="input-start-screen"
                value={input}
                placeholder="Enter screen ID"
                error={errorText()}
              ></ScoliaTextInput>

              <ScoliaButton
                text="Join"
                onPress={onSubmitButtonPressed}
                testID="submit-button-start-screen"
                width="100%"
              ></ScoliaButton>
            </View>
          }
        ></Card>
      </View>
      {Platform.OS == "web" && (
        <View style={{ zIndex: 2 }}>
          <PlayStore></PlayStore>
        </View>
      )}
    </View>
  );
}
