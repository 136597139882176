import analytics, { Analytics } from "./analytics";
import { Screen, LogEvent, ButtonId } from "./types";

class AnalitycsService {
  private readonly analytics: Analytics;

  constructor() {
    this.analytics = analytics;
  }

  setScreen(screen: Screen) {
    this.analytics.trackScreenView(screen);
  }

  setGameId(gameId: string) {
    this.analytics.setUserProperty("selectedGame", gameId);
  }

  setSelectedPlayers(players: Player[]) {
    this.analytics.setUserProperty("playerCount", players.length.toString());
    players.forEach((player, index) => {
      this.analytics.setUserProperty(`player${index}`, player.name);
    });
  }

  setGameConfiguration(configuration: Configuration) {
    this.analytics.setUserProperty("configuration", Object.keys(configuration).join(", "));
    Object.entries(configuration).forEach(([key, value]) => {
      this.analytics.setUserProperty(`configuration_${key}`, `${value}`);
    });

    this.logEvent(LogEvent.GAME_CONFIGURATION_CHANGED);
  }

  setSelectedGame(gameId: string) {
    this.analytics.setUserProperty("selectedGame", `${gameId}`);
    this.logEvent(LogEvent.GAME_SELECTED);
  }

  setUserId(id: string) {
    analytics.setUserId(id);
  }

  logEvent(event: LogEvent, params?: { [key: string]: string | number | boolean }) {
    this.analytics.logEvent(event, params);
  }

  logButtonClick(name: ButtonId, params?: { [key: string]: string | number | boolean }) {
    this.logEvent(LogEvent.BUTTON_CLICK, { name, ...params });
  }

  resetAnalyticsData() {
    this.analytics.resetAnalyticsData();
  }
}

export default new AnalitycsService();
