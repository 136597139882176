export const LINK_REQUEST = "linkRequest";
export const LINK_REQUEST_WITH_TOKEN = "linkRequestWithToken";
export const LINK_RESPONSE = "linkResponse";
export const UNLINK_REQUEST = "unlinkRequest";
export const UNLINK = "unlink";
export const CONFIG_CHANGED = "gameConfigurationChanged";
export const LIST_GAMES_REQUEST = "listGamesRequest";
export const LIST_GAMES_RESPONSE = "listGamesResponse";
export const SELECT_GAME_REQUEST = "selectGameRequest";
export const LOAD_GAME = "loadGame";
export const GAME_READY = "gameReady";
export const START_GAME = "startGame";
export const GAME_OVER = "gameOver";
export const ABORT_GAME = "abortGame";
export const PLAYER_LIST_CHANGED = "playerListChanged";
export const SWITCH_TO_NEXT_PLAYER = "switchToNextPlayer";
