export enum Screen {
  PH_01 = "PH-01 - Join Screen",
  PH_02 = "PH-02 - Select player Screen",
  PH_03 = "PH-03 - Add Player Screen",
  PH_05 = "PH-05 - Game List Screen",
  PH_07 = "PH-07 - Configuration Screen",
  PH_08 = "PH-08 - Game Screen",
  PH_10 = "PH-10 - LoadGame Screen"
}

export enum LogEvent {
  BUTTON_CLICK = "button_click",
  TAKE_PHOTO = "take_photo",
  LINK = "link",
  UNLINK = "unlink",
  UNLINK_REQUEST = "unlink_request",
  LINK_FAILED = "link_failed",
  PLAYER_SELECTED = "player_selected",
  PLAYER_DESELECTED = "player_deselected",
  PLAYER_ADDED = "player_added",
  PLAYER_REMOVED = "player_removed",
  FEEDBACK_REJECTED = "feedback_rejected",
  FEEDBACK_ACCEPTED = "feedback_accepted",
  CHANGE_GAME = "change_game",
  ABORT_GAME = "abort_game",
  GAME_CONFIGURATION_CHANGED = "game_configuration_changed",
  GAME_LIST_REQUEST = "game_list_request",
  GAME_LIST_RESPONSE = "game_list_response",
  GAME_SELECTED = "game_selected",
  GAME_LOAD = "game_load",
  GAME_LOADED = "game_loaded",
  GAME_START = "game_start",
  GAME_OVER = "game_over"
}

export enum ButtonId {
  PH_01_01 = "PH_01_01",
  PH_03_01 = "PH_03_01",
  PH_03_02 = "PH_03_02",
  PH_05_01 = "PH_05_01",
  PH_06_01 = "PH_06_01",
  PH_07_01 = "PH_07_01",
  PH_07_02 = "PH_07_02",
  PH_07_03 = "PH_07_03"
}
