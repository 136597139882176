import React, { useState, useCallback, useEffect } from "react";
import { FlatList, View, StyleSheet, BackHandler, Dimensions, Text } from "react-native";
import { CompositeNavigationProp, useFocusEffect } from "@react-navigation/native";
import { MaterialTopTabNavigationProp } from "@react-navigation/material-top-tabs";
import { StackNavigationProp } from "@react-navigation/stack";
import GameListItem from "../components/game-list-item";
import { useGameServiceContext } from "../context/game-service-context";
import { Game } from "../models/game";
import { PlayerPoolTabsParamList, LinkedStackParamList } from "../navigation";
import analyticsService from "../services/analytics/analytics-service";
import { ButtonId, Screen } from "../services/analytics/types";
import { useSelectedGameContext } from "../context/selected-game-context";
import LinearGradientContainer from "../components/linear-gradient-container";
import ScoliaButton from "../components/scolia-button";
import constants from "../constants/app-styles";

interface GameListScreenProps {
  navigation: CompositeNavigationProp<
    MaterialTopTabNavigationProp<PlayerPoolTabsParamList>,
    StackNavigationProp<LinkedStackParamList>
  >;
}

const createStyles = windowWidth =>
  StyleSheet.create({
    container: {
      flex: 1,
      marginHorizontal: windowWidth > 768 ? 60 : 20
    },
    selectGameText: {
      color: constants.colors.light2,
      fontWeight: "bold"
    },
    scoliaButton: {
      width: "100%",
      marginVertical: 16
    },
    selectGameTextFontStyle: {
      marginVertical: windowWidth > 768 ? 28 : 18,
      fontSize: windowWidth > 768 ? 24 : 16
    }
  });

export default function GameListScreen({ navigation }: GameListScreenProps) {
  const gameService = useGameServiceContext();
  const [games, setGames] = useState<Game[]>([]);
  const { setSelectedGame, selectedGame } = useSelectedGameContext();
  const windowWidth = Dimensions.get("window").width;

  useFocusEffect(
    useCallback(() => {
      gameService.sendListGamesRequest((response: Game[]) => {
        setGames(response);
      });
    }, [gameService])
  );

  useEffect(() => {
    if (games.length > 0) {
      setSelectedGame(games[0]);
    }
  }, [setSelectedGame, games]);

  useEffect(() => {
    if (selectedGame) {
      gameService.sendSelectGameRequest(selectedGame.gameId);
    }
  }, [selectedGame, gameService]);

  useFocusEffect(() => {
    analyticsService.setScreen(Screen.PH_05);
  });

  const onBackPress = () => true;

  const setBackHandler = () => {
    BackHandler.addEventListener("hardwareBackPress", onBackPress);
  };

  const cleanup = () => {
    BackHandler.removeEventListener("hardwareBackPress", onBackPress);
  };

  useFocusEffect(
    useCallback(() => {
      setBackHandler();

      return cleanup;
    }, [])
  );

  const isSelected = useCallback(
    ({ gameId }: Game) => {
      return selectedGame && selectedGame.gameId === gameId;
    },
    [selectedGame]
  );

  const getGameById = id => games.find(({ gameId }) => gameId === id);

  const goToPlayersTab = () => {
    analyticsService.logButtonClick(ButtonId.PH_05_01);
    setSelectedGame(getGameById(selectedGame.gameId));
    navigation.navigate("Players");
  };

  const styles = createStyles(windowWidth);

  return (
    <LinearGradientContainer>
      <View style={styles.container}>
        <Text style={[styles.selectGameText, styles.selectGameTextFontStyle]}>Select game type:</Text>
        {games && (
          <FlatList
            data={games}
            keyExtractor={game => game.gameId}
            extraData={[isSelected]}
            renderItem={({ item: game }) => (
              <GameListItem
                game={game}
                selected={isSelected(game)}
                onGameSelected={selectedGameId => {
                  setSelectedGame(getGameById(selectedGameId));
                  analyticsService.setGameId(selectedGameId);
                }}
              />
            )}
          />
        )}
        <ScoliaButton
          style={styles.scoliaButton}
          text="Continue to add players"
          onPress={goToPlayersTab}
          testID="submit-button-start-screen"
        ></ScoliaButton>
      </View>
    </LinearGradientContainer>
  );
}
