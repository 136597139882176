import React, { useState, useCallback } from "react";
import { StyleSheet, TextInput, View, Text, Platform, StyleProp, ViewStyle } from "react-native";
import contants from "../constants/app-styles";

const styles = StyleSheet.create({
  container: {
    height: 52,
    backgroundColor: contants.colors.dark3,
    borderRadius: 4,
    position: "relative"
  },
  inputStyleBasic: {
    fontSize: 16,
    color: contants.colors.light2,
    marginLeft: 6,
    marginRight: 6,
    height: 52,
    paddingLeft: 2
  },
  inputStyleOnFocus: {
    textAlignVertical: "bottom",
    position: "absolute",
    marginTop: 5,
    zIndex: 1,
    fontStyle: "normal",
    width: "96%"
  },
  inputStyleOnBlur: {
    fontStyle: "italic",
    textAlignVertical: "center"
  },
  placeholder: {
    fontStyle: "italic",
    color: contants.colors.grey3,
    fontSize: 12,
    marginTop: 6,
    marginLeft: 9,
    position: "absolute",
    zIndex: 2
  },
  errorBorder: {
    borderWidth: 2,
    borderColor: contants.colors.red
  },
  errorMsg: {
    fontSize: 14,
    color: contants.colors.red,
    marginTop: 5
  }
});

interface ScoliaTextInputProps {
  style?: StyleProp<ViewStyle>;
  onChange: (inputValue: string) => void;
  placeholder: string;
  error?: string;
  width?: string;
  value: string;
  maxLength?: number;
  testID?: string;
}

export default function ScoliaTextInput({
  onChange,
  placeholder,
  error,
  value,
  maxLength,
  testID,
  style
}: ScoliaTextInputProps) {
  const [focused, setFocused] = useState<boolean>(false);

  const inputStyle = useCallback(() => {
    return focused ? styles.inputStyleOnFocus : styles.inputStyleOnBlur;
  }, [focused]);

  const isError = error ? styles.errorBorder : {};

  const platformSpecificStyles = Platform.OS == "web" ? ({ outlineColor: "transparent" } as any) : {};

  return (
    <View style={style} testID={testID}>
      <View style={[styles.container, isError]}>
        {focused && <Text style={styles.placeholder}>{placeholder}</Text>}
        <TextInput
          placeholderTextColor={contants.colors.grey3}
          placeholder={focused ? "" : placeholder}
          spellCheck={false}
          autoCorrect={false}
          value={value}
          maxLength={maxLength}
          style={[styles.inputStyleBasic, inputStyle(), platformSpecificStyles]}
          secureTextEntry={false}
          keyboardType={Platform.OS === "android" ? "visible-password" : null}
          onChangeText={value => {
            onChange(value);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            if (value.length === 0) setFocused(false);
          }}
        />
      </View>
      {!!error && <Text style={styles.errorMsg}>{error}</Text>}
    </View>
  );
}
