import React from "react";
import { View, Text, Dimensions, StyleSheet } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { CompositeNavigationProp, useFocusEffect } from "@react-navigation/native";
import { MaterialTopTabNavigationProp } from "@react-navigation/material-top-tabs";
import { usePlayerListContext } from "../context/player-list-context";
import AddPlayerForm from "../components/add-player-form";
import { LinkedStackParamList, PlayerPoolTabsParamList } from "../navigation";
import analyticsService from "../services/analytics/analytics-service";
import { Screen } from "../services/analytics/types";
import LinearGradientContainer from "../components/linear-gradient-container";
import Toolbar from "../components/toolbar";
import constants from "../constants/app-styles";

interface AddPlayerScreenProps {
  navigation: CompositeNavigationProp<
    StackNavigationProp<LinkedStackParamList, "AddPlayer">,
    MaterialTopTabNavigationProp<PlayerPoolTabsParamList>
  >;
}

export default function AddPlayerScreen({ navigation }: AddPlayerScreenProps) {
  const { players, addPlayer } = usePlayerListContext();
  const windowWidth = Dimensions.get("window").width;
  const isNameTaken = (name: string) => !!players.find(player => player.name === name);

  const addNewPlayer = (player: Player) => {
    if (player.name && !isNameTaken(player.name)) {
      player.selected = true;
      addPlayer(player);
      navigation.goBack();
    }
  };

  useFocusEffect(() => {
    analyticsService.setScreen(Screen.PH_03);
  });

  const styles = createStyles(windowWidth);

  return (
    <LinearGradientContainer>
      <Toolbar
        canGoBack
        customContent={
          <View
            style={{
              flex: 1,
              alignItems: "center"
            }}
          >
            <Text style={styles.toolbarText}>Add new player</Text>
          </View>
        }
      />
      <View style={styles.mainContainer}>
        <AddPlayerForm onSubmit={addNewPlayer} isNameTaken={isNameTaken} />
      </View>
    </LinearGradientContainer>
  );
}

const createStyles = windowWidth =>
  StyleSheet.create({
    mainContainer: {
      flex: 1,
      justifyContent: "center",
      marginHorizontal: windowWidth > 768 ? 60 : 20
    },
    toolbarText: {
      fontSize: 16,
      color: constants.colors.light2
    }
  });
