import React, { useCallback } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useFocusEffect } from "@react-navigation/native";
import { SelectedPlayersProvider } from "../context/selected-players-context";
import ConfigurationScreen from "../screens/configuration-screen";
import GameScreen from "../screens/game-screen";
import { useGameServiceContext } from "../context/game-service-context";
import LoadGameScreen from "../screens/load-game-screen";
import { GameStackParamList } from ".";

const GameStack = createStackNavigator<GameStackParamList>();

const GameNavigator = props => {
  const gameService = useGameServiceContext();

  useFocusEffect(
    useCallback(() => {
      return () => gameService.sendAbortGameEvent();
    }, [])
  );

  return (
    <SelectedPlayersProvider>
      <GameStack.Navigator {...props} initialRouteName="Configuration" headerMode="none">
        <GameStack.Screen name="Configuration" component={ConfigurationScreen} />
        <GameStack.Screen name="Game" component={GameScreen} />
        <GameStack.Screen name="LoadGame" component={LoadGameScreen} />
      </GameStack.Navigator>
    </SelectedPlayersProvider>
  );
};

export default GameNavigator;
