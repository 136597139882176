export const renderNumberOfPlayers = (numberOfPlayers: Array<number>) => {
  let numberOfPlayersText;

  if (Math.max(...numberOfPlayers) - Math.min(...numberOfPlayers) + 1 === numberOfPlayers.length) {
    numberOfPlayersText = `${Math.min(...numberOfPlayers)}-${Math.max(...numberOfPlayers)}`;
  } else {
    numberOfPlayersText = numberOfPlayers.join(",");
  }

  return numberOfPlayersText;
};
