import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import io from "socket.io-client";
import env from "../../env";
import GameServiceContext from "../context/game-service-context";
import { GameService } from "../services/game-service";
import RootNavigator from "./root-stack";
export * from "./types";

export const AppContainer = () => (
  <NavigationContainer>
    <GameServiceContext.Provider value={new GameService(io(env.SERVER_URL, { query: { type: "configurator" } }))}>
      <RootNavigator />
    </GameServiceContext.Provider>
  </NavigationContainer>
);
