import React from "react";
import { StyleSheet, StyleProp, ViewStyle } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialIcons } from "@expo/vector-icons";
import constants from "../constants/app-styles";

const styles = StyleSheet.create({
  IconButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    height: 50,
    borderRadius: 25
  }
});

interface SoliaIconButtonProps {
  style?: StyleProp<ViewStyle>;
  icon: string;
  onPress: () => void;
  testID?: string;
}

export default function SoliaIconButton({ icon, onPress, testID, style }: SoliaIconButtonProps) {
  return (
    <TouchableWithoutFeedback onPress={onPress} testID={testID} style={style}>
      {
        <LinearGradient colors={[constants.colors.primaryLight, constants.colors.primary]} style={styles.IconButton}>
          <MaterialIcons name={icon} size={26} color="black" />
        </LinearGradient>
      }
    </TouchableWithoutFeedback>
  );
}
