import React from "react";
import { StyleSheet, View, Text, StyleProp, ViewStyle } from "react-native";
import constants from "../constants/app-styles";

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    backgroundColor: constants.colors.dark2,
    flexDirection: "row"
  },
  containerShadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  left: {
    backgroundColor: "transparent",
    alignItems: "center",
    paddingRight: 10
  },
  titleAndContentContainer: {
    backgroundColor: "transparent",
    flexDirection: "column"
  },
  titleContainer: {
    backgroundColor: "transparent",
    justifyContent: "center"
  },
  titleText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#fff"
  },
  contentContainer: {
    backgroundColor: "transparent",
    justifyContent: "flex-start"
  },
  contentText: {
    fontSize: 14,
    color: "#fff"
  }
});

interface CardProps {
  containerStyle?: StyleProp<ViewStyle>;
  left?: React.ReactNode;
  title?: React.ReactNode | string;
  content?: React.ReactNode | string;
}

export default function Card({ containerStyle, left, title, content }: CardProps) {
  const isTitleString = typeof title === "string";
  const IsContentString = typeof content === "string";

  return (
    <View style={[containerStyle, styles.container, styles.containerShadow]}>
      {left && <View style={styles.left}>{left}</View>}
      <View style={[styles.titleAndContentContainer, { flex: 1 }]}>
        {title && (
          <>
            {!isTitleString && title}
            {isTitleString && (
              <View style={styles.titleContainer}>
                <Text style={styles.titleText}>{title}</Text>
              </View>
            )}
          </>
        )}
        {content && (
          <>
            {!IsContentString && content}
            {IsContentString && (
              <View style={styles.contentContainer}>
                <Text style={styles.contentText}>{content}</Text>
              </View>
            )}
          </>
        )}
      </View>
    </View>
  );
}
