import React, { useCallback, useState } from "react";
import { BackHandler, StyleSheet, Text, View, Image, Platform, Linking } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { CompositeNavigationProp, useFocusEffect } from "@react-navigation/native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import env from "../../env";
import { useGameServiceContext } from "../context/game-service-context";
import { GameStackParamList, PlayerPoolTabsParamList, RootStackParamList } from "../navigation";
import { ButtonId, LogEvent, Screen } from "../services/analytics/types";
import analyticsService from "../services/analytics/analytics-service";
import { useSelectedGameContext } from "../context/selected-game-context";
import { useFeedback } from "../hooks/feedback";
import LinearGradientContainer from "../components/linear-gradient-container";
import ScoliaButton from "../components/scolia-button";
import constants from "../constants/app-styles";
import firework from "../styles/resources/images/firework_explosion.png";
import confirm from "../services/confirmation-service";

type GameScreenNavigationProp = CompositeNavigationProp<
  CompositeNavigationProp<
    StackNavigationProp<PlayerPoolTabsParamList, "Games">,
    StackNavigationProp<RootStackParamList, "Start">
  >,
  StackNavigationProp<GameStackParamList, "Game">
>;

interface GameScreenProps {
  navigation: GameScreenNavigationProp;
}

export default function GameScreen({ navigation }: GameScreenProps) {
  const gameService = useGameServiceContext();
  const { selectedGame } = useSelectedGameContext();

  const [isGameOver, setIsGameOver] = useState<boolean>(false);

  const onBackPress = () => true;

  const init = () => {
    gameService.sendStartGameEvent(() => {
      setIsGameOver(true);
    });

    BackHandler.addEventListener("hardwareBackPress", onBackPress);
  };

  const cleanup = () => {
    BackHandler.removeEventListener("hardwareBackPress", onBackPress);
  };

  useFocusEffect(
    useCallback(() => {
      init();

      return cleanup;
    }, [])
  );

  useFocusEffect(() => {
    analyticsService.setScreen(Screen.PH_08);
  });

  const showFeedbackDialogIfNecessary = useFeedback();

  const navigateToGameList = () => {
    analyticsService.logEvent(LogEvent.CHANGE_GAME);
    navigation.navigate("Games");
  };

  return (
    <LinearGradientContainer>
      <View style={styles.mainContainer}>
        <View style={styles.contentContainer}>
          <Text style={styles.gameTitle}>{selectedGame.name}</Text>
          {!isGameOver && (
            <>
              <Image
                testID="text-game-icon"
                source={{ uri: env.SERVER_URL + selectedGame.icon }}
                style={styles.gameIcon}
              />
              <Text style={styles.gameRunningText} testID="text-game-name">
                Game is running...
              </Text>
              <TouchableWithoutFeedback
                style={styles.switchPlayerTextContainer}
                onPress={() =>
                  confirm({
                    title: "Switch Player",
                    message: "Are you sure the dartboard is clear and you want to switch to the next player?",
                    confirmText: "Yes, switch",
                    cancelText: "Cancel",
                    onConfirm: () => gameService.sendSwitchToNextPlayer()
                  })
                }
              >
                <Text style={styles.underlinedText} testID="text-switch-player">
                  Switch Player
                </Text>
              </TouchableWithoutFeedback>
            </>
          )}
          {isGameOver && (
            <>
              <Image testID="firework-explosion" source={firework} style={styles.fireworkIcon} />
              <View style={styles.gameOverTextContainer}>
                <Text style={styles.gameOverText} testID="text-game-over">
                  Thank you for playing!
                </Text>
                <TouchableWithoutFeedback
                  style={styles.feedbackFormTextContainer}
                  onPress={() => {
                    if (Platform.OS == "web") {
                      window.open(env.FEEDBACK_FORM_URL, "_blank", "noopener");
                    } else {
                      Linking.openURL(env.FEEDBACK_FORM_URL);
                    }
                  }}
                >
                  <Text style={styles.underlinedText} testID="text-feedback-form">
                    Tell us what you think!
                  </Text>
                </TouchableWithoutFeedback>
              </View>
            </>
          )}
        </View>

        <View style={styles.buttonContainer}>
          <ScoliaButton
            testID="button-restart"
            variant="flat"
            iconPosition="top"
            text="Restart Game"
            icon="replay"
            onPress={() =>
              confirm({
                title: "Restart Game",
                message: "Are you sure you would like to abort the game?",
                confirmText: "Yes, restart game",
                cancelText: "Cancel",
                onConfirm: () => {
                  analyticsService.logButtonClick(ButtonId.PH_07_01, { isGameOver: isGameOver });
                  navigation.navigate("LoadGame");
                }
              })
            }
          />
          <ScoliaButton
            testID="button-change-game"
            variant="flat"
            iconPosition="top"
            text="Back to Menu"
            icon="shuffle"
            onPress={() =>
              confirm({
                title: "Back to Menu",
                message: "Are you sure you would like to abort the game?",
                confirmText: "Yes, back to Menu",
                cancelText: "Cancel",
                onConfirm: () => {
                  analyticsService.logButtonClick(ButtonId.PH_07_02, { isGameOver: isGameOver });
                  navigateToGameList();
                  showFeedbackDialogIfNecessary();
                }
              })
            }
          />
        </View>
      </View>
    </LinearGradientContainer>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 20
  },
  gameTitle: {
    color: constants.colors.light2,
    fontWeight: "bold",
    fontSize: 24,
    marginBottom: 48
  },
  gameIcon: {
    width: 160,
    height: 160
  },
  fireworkIcon: {
    width: 100,
    height: 100
  },
  gameRunningText: {
    fontSize: 16,
    color: constants.colors.grey3,
    marginTop: 8
  },
  gameOverTextContainer: {
    marginTop: 20,
    alignItems: "center"
  },
  gameOverText: {
    fontSize: 16,
    color: constants.colors.light2
  },
  feedbackFormTextContainer: {
    marginTop: 16
  },
  underlinedText: {
    fontSize: 16,
    color: constants.colors.primaryLight,
    textDecorationLine: "underline"
  },
  switchPlayerTextContainer: {
    marginTop: 40
  }
});
