import { createStackNavigator } from "@react-navigation/stack";
import React, { useState } from "react";
import { useGameServiceContext } from "../context/game-service-context";
import StartScreen from "../screens/start-screen";
import { useFeedback } from "../hooks/feedback";
import ActionBar from "../components/action-bar";
import LinkedNavigator from "./linked-stack";
import { RootStackParamList } from ".";

const RootStack = createStackNavigator<RootStackParamList>();

const RootNavigator = () => {
  const gameService = useGameServiceContext();
  const [linked, setLinked] = useState<boolean>(false);

  const showFeedbackDialogIfNecessary = useFeedback();

  gameService.onLinkedChanged(isLinked => {
    setLinked(isLinked);

    if (!isLinked) {
      showFeedbackDialogIfNecessary();
    }
  });

  return (
    <RootStack.Navigator initialRouteName="Start">
      {!linked && <RootStack.Screen name="Start" component={StartScreen} options={{ header: () => null }} />}
      {linked && <RootStack.Screen name="Linked" component={LinkedNavigator} options={{ header: () => null }} />}
    </RootStack.Navigator>
  );
};

export default RootNavigator;
