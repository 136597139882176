import * as React from "react";
import { StyleSheet, Image, View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { MaterialIcons } from "@expo/vector-icons";
import logo from "../styles/resources/images/scoliaLogo.png";
import { useGameServiceContext } from "../context/game-service-context";
import constants from "../constants/app-styles";
import confirm from "../services/confirmation-service";

export default function ActionBar() {
  const gameService = useGameServiceContext();

  return (
    <View style={styles.actionBar}>
      <Image source={logo} style={styles.logo} />
      <TouchableWithoutFeedback
        testID="button-logout"
        onPress={() =>
          confirm({
            title: "Quit App",
            message: "Are you sure you would like to quit?",
            confirmText: "Yes, quit",
            cancelText: "Cancel",
            onConfirm: () => {
              gameService.sendUnlinkRequest();
            }
          })
        }
      >
        <MaterialIcons name="exit-to-app" size={26} color={constants.colors.grey3} />
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  actionBar: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 21,
    flexDirection: "row",
    backgroundColor: constants.colors.dark1
  },
  logo: {
    width: 50,
    height: 58
  }
});
