import localStorage from "redux-persist/es/storage";

export const setLastShown = async () => {
  try {
    await localStorage.setItem("lastShown", new Date().getTime().toString());
  } catch (error) {
    console.log(error);
  }
};

export const setLastAccepted = async () => {
  try {
    await localStorage.setItem("lastAccepted", new Date().getTime().toString());
  } catch (error) {
    console.log(error);
  }
};

export const getLastShown = async () => {
  return await localStorage.getItem("lastShown");
};

export const getLastAccepted = async () => {
  return await localStorage.getItem("lastAccepted");
};
