import { createTransform } from "redux-persist";

const initPlayers = createTransform(
  inboundState => inboundState,
  (outboundState: Player[]) => {
    outboundState.forEach(player => {
      player.selected = false;
    });
    return outboundState;
  },
  { whitelist: ["players"] }
);

export default initPlayers;
