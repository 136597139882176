import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AppRoot from "./src/main";

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});

// Disable back button behaviour to prevent accidentally closing the app
if (Platform.OS === "web") {
  window.addEventListener("load", function() {
    window.history.pushState({}, "");
  });

  window.addEventListener("popstate", function() {
    window.history.pushState({}, "");
  });
}

export default function App() {
  return (
    <SafeAreaProvider
      initialMetrics={{
        frame: { x: 0, y: 0, width: 0, height: 0 },
        insets: { top: 0, left: 0, right: 0, bottom: 0 }
      }}
    >
      <View style={styles.container}>
        <AppRoot />
      </View>
    </SafeAreaProvider>
  );
}
