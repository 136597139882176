import { Linking, Platform } from "react-native";
import env from "../../env";
import confirm from "../services/confirmation-service";
import analyticsService from "../services/analytics/analytics-service";
import { LogEvent } from "../services/analytics/types";
import { setLastShown, setLastAccepted, getLastShown, getLastAccepted } from "./feedback-utils";

const showFeedbackConfirmationDialog = () => {
  setLastShown();

  confirm({
    title: "Thank your for Playing",
    message: "How can we improve Scolia Social? We need your opinion",
    confirmText: "I would like to help",
    cancelText: "Not now",
    onConfirm: async () => {
      analyticsService.logEvent(LogEvent.FEEDBACK_ACCEPTED);

      setLastAccepted();

      if (Platform.OS == "web") {
        window.open(env.FEEDBACK_FORM_URL, "_blank", "noopener");
      } else {
        await Linking.openURL(env.FEEDBACK_FORM_URL);
      }
    },
    onCancel: () => {
      analyticsService.logEvent(LogEvent.FEEDBACK_REJECTED);
    }
  });
};

const getDaysAgo = timestamp => {
  return (new Date().getTime() - timestamp) / (24 * 60 * 60 * 1000);
};

export const useFeedback = () => {
  return async () => {
    const lastAccepted = await getLastAccepted();
    const lastShown = await getLastShown();

    if ((!lastAccepted || getDaysAgo(lastAccepted) > 30) && (!lastShown || getDaysAgo(lastShown) > 1)) {
      showFeedbackConfirmationDialog();
    }
  };
};
