import Jimp from "jimp";
import env from "../../env";

export const cropImage = async photo => {
  const image = await Jimp.read(photo.uri);
  const croppedImageSize = +env.CROPPED_IMAGE_SIZE;

  if (image.bitmap.width < croppedImageSize) {
    image.resize(croppedImageSize, Jimp.AUTO);
  }

  const x = image.bitmap.width > croppedImageSize ? (image.bitmap.width - croppedImageSize) / 2 : 0;
  const y = image.bitmap.height > croppedImageSize ? (image.bitmap.height - croppedImageSize) / 2 : 0;

  image.crop(x, y, croppedImageSize, croppedImageSize);
  return image.getBase64Async(Jimp.MIME_JPEG);
};

export const isCameraAvailable = async (): Promise<boolean> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    stream.getTracks().forEach(track => track.stop());
    return true;
  } catch (e) {
    return false;
  }
};
