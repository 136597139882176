import React, { useState } from "react";
import { StyleSheet, View, Linking, Text, Image } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import env from "../../env";
import constants from "../constants/app-styles";
import appStore from "../styles/resources/images/appStore.png";
import ScoliaButton from "./scolia-button";

const styles = StyleSheet.create({
  playStoreContainer: {
    flexDirection: "row",
    width: "100%",
    height: 72,
    backgroundColor: constants.colors.dark2
  },
  textContainer: {
    flex: 1.5,
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    fontSize: 14,
    fontWeight: "bold",
    color: constants.colors.grey4,
    paddingHorizontal: 40
  },
  badgeContainer: {
    flex: 1.5,
    justifyContent: "center",
    alignItems: "center"
  },
  buttonContainer: {
    flex: 0.5,
    justifyContent: "center",
    alignItems: "center"
  },
  image: {
    height: 41,
    width: 136
  }
});

export default function PlayStore() {
  const [isPlayStoreActive, setIsPlayStoreActive] = useState<boolean>(true);

  return (
    <>
      {isPlayStoreActive && (
        <View style={styles.playStoreContainer}>
          <View style={styles.textContainer}>
            <Text style={styles.text}>Want a better experience?</Text>
          </View>
          <View style={styles.badgeContainer}>
            <TouchableWithoutFeedback onPress={() => Linking.openURL(env.PLAYSTORE_URL)}>
              <Image style={styles.image} source={appStore} />
            </TouchableWithoutFeedback>
          </View>
          <View style={styles.buttonContainer}>
            <ScoliaButton
              icon="close"
              onPress={() => setIsPlayStoreActive(false)}
              variant="flat"
              iconColor={constants.colors.dark4}
            ></ScoliaButton>
          </View>
        </View>
      )}
    </>
  );
}
