import React from "react";
import { Provider } from "react-redux";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./store";
import { AppContainer } from "./navigation";
import { colors } from "./styles/theme";

const { store, persistor } = configureStore();

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: colors.primary,
    accent: colors.secondary
  }
};

const Container = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <PaperProvider theme={theme}>
        <AppContainer />
      </PaperProvider>
    </PersistGate>
  </Provider>
);

export default Container;
