import * as firebase from "firebase/app";
import "firebase/analytics";

export interface Analytics {
  logEvent: (name: string, params?: { [key: string]: string | number | boolean }) => Promise<void>;
  trackScreenView: (screen: string) => Promise<void>;
  resetAnalyticsData: () => Promise<void>;
  setUserId: (id: string | null) => Promise<void>;
  setUserProperty: (key: string, value: string | null) => Promise<void>;
}

const firebaseConfig = {
  apiKey: "AIzaSyAcm1-KxjEJqPuwBpAHlTsDahP_VcyzRJQ",
  authDomain: "scolia-social-a6b4e.firebaseapp.com",
  databaseURL: "https://scolia-social-a6b4e.firebaseio.com",
  projectId: "scolia-social-a6b4e",
  storageBucket: "scolia-social-a6b4e.appspot.com",
  messagingSenderId: "661997609665",
  appId: "1:661997609665:web:47afd14de80f4a1c7e2a40",
  measurementId: "G-JSVCY6QYJK"
};

class WebAnalytics implements Analytics {
  constructor() {
    firebase.initializeApp(firebaseConfig);
  }

  logEvent(name: string, params?: { [key: string]: string | number | boolean }): Promise<void> {
    firebase.analytics().logEvent(name, params);
    return Promise.resolve();
  }
  trackScreenView(screen: string): Promise<void> {
    firebase.analytics().setCurrentScreen(screen);
    return Promise.resolve();
  }
  resetAnalyticsData(): Promise<void> {
    this.setUserId(null);
    return Promise.resolve();
  }
  setUserId(id: string): Promise<void> {
    firebase.analytics().setUserId(id);
    return Promise.resolve();
  }
  setUserProperty(key: string, value: string): Promise<void> {
    const property = {};
    property[key] = value;
    firebase.analytics().setUserProperties(property);
    return Promise.resolve();
  }
}

export default new WebAnalytics();
