import React from "react";

interface PlayerListContextType {
  players: Player[];
  updatePlayer: (player: Player, values: Partial<Player>) => void;
  removePlayer: (player: Player) => void;
  addPlayer: (player: Player) => void;
  selectedPlayers: Player[];
}

export const PlayerListContext = React.createContext<PlayerListContextType>({
  players: [],
  updatePlayer: () => undefined,
  removePlayer: () => undefined,
  addPlayer: () => undefined,
  selectedPlayers: []
});
PlayerListContext.displayName = "PlayerList";
