import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../styles/confirm-alert.css";
import { View, Text } from "react-native";
import Card from "../components/card";
import constants from "../constants/app-styles";
import ScoliaButton from "../components/scolia-button";

export interface ConfirmationOption {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default function confirm({
  title,
  message,
  confirmText = "Yes",
  cancelText = "No",
  onConfirm,
  onCancel
}: ConfirmationOption) {
  const customUI = (title, message, confirmText, cancelText, onConfirm, onCancel, onClose) => {
    return (
      <Card
        containerStyle={{
          left: 20,
          right: 20,
          bottom: 128,
          position: "absolute",
          paddingHorizontal: 24,
          paddingVertical: 22
        }}
        title={
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <Text style={{ fontSize: 20, fontWeight: "bold", color: constants.colors.light2 }}>{title}</Text>
          </View>
        }
        content={
          <View style={{ flex: 1, alignItems: "center", marginTop: 16 }}>
            <Text style={{ fontSize: 16, color: constants.colors.grey4, textAlign: "center" }}>{message}</Text>
            <ScoliaButton
              style={{ marginBottom: 20, marginTop: 24, width: "100%" }}
              onPress={() => {
                onConfirm && onConfirm();
                onClose();
              }}
              text={confirmText}
            />
            <ScoliaButton
              variant="flat"
              onPress={() => {
                onCancel && onCancel();
                onClose();
              }}
              text={cancelText}
            />
          </View>
        }
      />
    );
  };

  confirmAlert({
    customUI: ({ onClose }) => customUI(title, message, confirmText, cancelText, onConfirm, onCancel, onClose),
    onClickOutside: () => onCancel && onCancel()
  });
}
