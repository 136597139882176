import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { MaterialIcons } from "@expo/vector-icons";
import env from "../../env";
import { Game } from "../models/game";
import { renderNumberOfPlayers } from "../utils/number-of-players";
import constants from "../constants/app-styles";
import Card from "./card";

interface GameListElementProps {
  game: Game;
  selected: boolean;
  onGameSelected: (gameId: string) => void;
}

export default function GameListItem({ game, selected, onGameSelected }: GameListElementProps) {
  const cardStyle = selected ? styles.itemActive : {};
  const titleColor = selected ? constants.colors.primaryLight : constants.colors.light2;
  const contentColor = selected ? constants.colors.primaryLight : constants.colors.grey3;

  return (
    <View style={[styles.container]}>
      <TouchableWithoutFeedback onPress={() => onGameSelected(game.gameId)}>
        <Card
          containerStyle={[{ padding: 11, borderWidth: 2, borderColor: "transparent" }, cardStyle]}
          left={<Image source={{ uri: env.SERVER_URL + game.icon }} style={styles.icon} />}
          title={
            <View style={styles.titleContainer}>
              <Text style={[{ color: titleColor }, styles.titleBasic]}>{game.name}</Text>
              <View style={styles.titleRightContentContainer}>
                <MaterialIcons name="person" size={20} color={titleColor} />
                <Text style={[{ color: titleColor }, styles.contentBasic]}>
                  {renderNumberOfPlayers(game.numberOfPlayers)}
                </Text>
              </View>
            </View>
          }
          content={<Text style={[{ color: contentColor }, styles.contentBasic]}>{game.shortDescription}</Text>}
        ></Card>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    marginVertical: 7,
    width: "100%"
  },
  itemActive: {
    borderColor: constants.colors.primaryLight
  },
  titleContainer: {
    justifyContent: "space-between",
    flexDirection: "row"
  },
  titleRightContentContainer: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  titleBasic: {
    fontSize: 16,
    fontWeight: "bold"
  },
  titleRightContentText: {
    fontSize: 14,
    marginLeft: 5
  },
  contentBasic: {
    fontSize: 14
  },
  icon: {
    width: 40,
    height: 40
  }
});
