export default {
  colors: {
    primary: "#5AAB30",
    primaryLight: "#9AC541",
    lightGrey: "#606060",
    red: "#F25F5F",
    yellow: "#FFC700",
    blue: "#40C4FF",
    dark1: "#0C0C0C",
    dark2: "#1B1C1B",
    dark3: "#282928",
    dark4: "#444942",
    grey1: "#6C7468",
    grey2: "#929C8C",
    grey3: "#AAAAAA",
    grey4: "#DADADA",
    light1: "#F7F7F7",
    light2: "#FFFFFF"
  }
};
