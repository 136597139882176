import React, { useCallback } from "react";
import { ActivityIndicator, StyleSheet, View, Text } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { usePlayerListContext } from "../context/player-list-context";
import { useGameServiceContext } from "../context/game-service-context";
import { GameStackParamList } from "../navigation";
import analyticsService from "../services/analytics/analytics-service";
import { Screen } from "../services/analytics/types";
import { useSelectedGameContext } from "../context/selected-game-context";
import LinearGradientContainer from "../components/linear-gradient-container";
import ScoliaButton from "../components/scolia-button";
import constants from "../constants/app-styles";

type LoadGameScreenNavigationProp = StackNavigationProp<GameStackParamList, "Configuration">;

interface LoadGameScreenProps {
  navigation: LoadGameScreenNavigationProp;
}

export default function LoadGameScreen({ navigation }: LoadGameScreenProps) {
  const { selectedPlayers } = usePlayerListContext();
  const gameService = useGameServiceContext();
  const { selectedGame } = useSelectedGameContext();
  const { extraParams } = selectedGame;

  const navigateToGameScreen = () => {
    navigation.navigate("Game");
  };

  const navigateToConfigScreen = () => {
    navigation.navigate("Configuration");
  };

  const restartGame = () => {
    navigation.navigate("LoadGame");
  };

  const navigateBackToMenu = () => {
    navigation.navigate("Games");
  };

  const init = (players: Player[]) => {
    gameService.sendLoadGameEvent(() => {
      gameService.sendPlayerListChangedEvent(players);

      return extraParams.length > 0 ? navigateToConfigScreen() : navigateToGameScreen();
    });
  };

  useFocusEffect(
    useCallback(() => {
      init(selectedPlayers);
    }, [selectedPlayers])
  );

  useFocusEffect(() => {
    analyticsService.setScreen(Screen.PH_10);
  });

  return (
    <LinearGradientContainer>
      <View style={styles.mainContainer}>
        <View style={styles.contentContainer}>
          <Text style={styles.gameTitle}>{selectedGame.name}</Text>
          <ActivityIndicator size="large" color={constants.colors.primaryLight} />
          <Text style={styles.contentText}>Game is loading...</Text>
        </View>

        <View style={styles.buttonContainer}>
          <ScoliaButton variant="flat" iconPosition="top" text="Restart Game" icon="replay" onPress={restartGame} />
          <ScoliaButton
            variant="flat"
            iconPosition="top"
            text="Back to Menu"
            icon="shuffle"
            onPress={navigateBackToMenu}
          />
        </View>
      </View>
    </LinearGradientContainer>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 20
  },
  gameTitle: {
    color: constants.colors.light2,
    fontWeight: "bold",
    fontSize: 24,
    marginBottom: 80
  },
  contentText: {
    color: constants.colors.light2,
    fontSize: 16,
    marginTop: 20
  }
});
