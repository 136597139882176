import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { SafeAreaView } from "react-native-safe-area-context";
import AddPlayerScreen from "../screens/add-player-screen";
import PlayerListProvider from "../context/player-list-context/player-list-provider";
import { SelectedGameProvider } from "../context/selected-game-context/selected-game-provider";
import ActionBar from "../components/action-bar";
import contants from "../constants/app-styles";
import PlayerPoolNavigator from "./player-pool-tabs";
import GameNavigator from "./game-stack";
import { LinkedStackParamList } from ".";

const LinkedStack = createStackNavigator<LinkedStackParamList>();

const actionBar = () => <ActionBar />;

const LinkedNavigator = props => (
  <SafeAreaView style={{ flex: 1, backgroundColor: contants.colors.dark1 }}>
    <PlayerListProvider>
      <SelectedGameProvider>
        <LinkedStack.Navigator {...props} initialRouteName="PlayerPool">
          <LinkedStack.Screen name="PlayerPool" component={PlayerPoolNavigator} options={{ header: actionBar }} />
          <LinkedStack.Screen name="AddPlayer" component={AddPlayerScreen} options={{ header: actionBar }} />
          <LinkedStack.Screen name="GameStack" component={GameNavigator} options={{ header: actionBar }} />
        </LinkedStack.Navigator>
      </SelectedGameProvider>
    </PlayerListProvider>
  </SafeAreaView>
);

export default LinkedNavigator;
