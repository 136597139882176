import React from "react";
import { Text, View } from "react-native";
import constants from "../constants/app-styles";

export default function EmptyPlayerPool() {
  return (
    <View style={{ flex: 1 }}>
      <Text style={{ fontSize: 16, textAlign: "center", color: constants.colors.grey3 }}>
        Click on &quot;Add new player&quot; above
      </Text>
    </View>
  );
}
