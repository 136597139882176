import React, { useContext, useState, PropsWithChildren } from "react";

interface SelectedPlayersContextType {
  selectedPlayers: Player[];
  setSelectedPlayers: (player: Player[]) => void;
}

const SelectedPlayersContext = React.createContext<SelectedPlayersContextType>({
  selectedPlayers: [],
  setSelectedPlayers: () => undefined
});
SelectedPlayersContext.displayName = "SelectedPlayers";

export const SelectedPlayersProvider = (props: PropsWithChildren<{}>) => {
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);

  return (
    <SelectedPlayersContext.Provider
      value={{ selectedPlayers, setSelectedPlayers: (players: Player[]) => setSelectedPlayers(players) }}
    >
      {props.children}
    </SelectedPlayersContext.Provider>
  );
};
export const useSelectedPlayersContext = () => useContext(SelectedPlayersContext);
